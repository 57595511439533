@font-face {
  font-family: 'KyivTypeSans';
  src: url('./assets/fonts/KyivTypeSans-Regular2.ttf') format('truetype');
}

@font-face {
  font-family: 'KyivTypeTitling-Heavy';
  src: url('./assets/fonts/KyivTypeTitling-Heavy2.ttf') format('truetype');
}

@font-face {
  font-family: 'KyivTypeSans-Bold';
  src: url('./assets/fonts/KyivTypeSans-Bold2.ttf') format('truetype');
}

:root {
  --primary-black: black;
  --button-color-one: #456EBD;
  --button-color-two: #9D2555;
  --button-color-three: #916d55;
}

* {
  margin: 0;
  font-family: 'KyivTypeSans', 'Lato', 'Open Sans', sans-serif;
  font-size: 20px;
}

body,
html {
  overflow: hidden;
}

.App {
  height: calc(var(--vh, 1vh) * 100);
}

.shape {
  position: absolute;
  animation: rotate 15s linear infinite;
  z-index: -1;
}


/*NAVBAR*/
.navbar {
  height: 7.5rem;
  display: flex;
  align-items: center;
}

.navbar .links {
  margin-right: 8vw;
  margin-left: auto;
}

.navbar a {
  margin-left: 2.25rem;
  font-size: 1.25rem;
}

.navbar a:hover {
  color: var(--button-color-three);
}

#main-logo {
  margin-left: 8vw;
  padding-left: 0;
  font-family: 'KyivTypeTitling-Heavy', 'KyivTypeSans-Bold', sans-serif;
  font-size: 1.25rem;
}

#main-logo:hover {
  cursor: pointer;
  color: var(--primary-black);
}


/*HOME PAGE*/
.home {
  margin: 0 0 20vh 45vw;
  display: flex;
  flex-direction: column;
  min-height: 16rem;
  width: 28.5rem;
  justify-content: center;
  height: calc(100vh - 15rem);
}

.home h1 {
  margin: 0 0 0.4rem -0.2rem;
}

h1 {
  margin-bottom: 1.25rem;
  font-size: 3rem;
  font-family: 'KyivTypeSans-Bold', 'Lato-Bold', 'Open Sans-Bold', sans-serif;
}

p {
  font-size: 1rem;
  line-height: 140%;
  margin-bottom: 1.5rem;
  word-spacing: 0.4rem;
  text-align: justify;
}

#p1 {
  margin-bottom: 0.4rem;
}

.image--left {
  /* top: calc(50vh - 20rem); */
  left: -15rem;
  opacity: 0.8;
  display: flex;
  justify-self: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button__container {
  min-width: 20rem;
  max-width: fit-content;
  display: flex;
  justify-content: space-between;
}

.button {
  padding: 0.5rem 0;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  max-width: 12.5rem;
  text-align: left;
  font-family: 'KyivTypeSans-Bold', 'Lato-Bold', 'Open Sans-Bold', sans-serif;
  cursor: pointer;
  text-decoration: none;
  color: var(--primary-black);
}

.blue:hover {
  color: var(--button-color-one);
  background-image: url('./assets/images/arrow-wave-left-up-blue.svg');
}

/*ABOUT PAGE*/
.about,
.works {
  height: calc(100vh - 15rem);
  display: flex;
  justify-content: space-between;
  width: 84vw;
  margin: 0 8vw;
}

.about {
  align-items: center;
}

.image--right {
  top: calc(1vh - 22.5rem);
  left: 70vw;
  opacity: 0.3;
}

.about__container--left {
  max-width: 32.5rem;
}

/*WORKS PAGE*/
.works {
  align-items: flex-start;
}

.works__container--right,
.image__container {
  /* min-width: 22.5rem;
  min-height: 30rem; */
  display: flex;
  flex-direction: column;
}

.works__table {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.works-elem__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.line {
  margin-bottom: 1rem;
  background-image: url('./assets//images/wave-line-450.svg');
  width: 450px;
  height: 10px;
}

.red:hover {
  color: var(--button-color-two);
  background-image: url('./assets/images/arrow-wave-left-up-red.svg');
}

@keyframes zoomIn {
  0% {
    transform: scale(0.8);
    /* Start with the image not visible */
    opacity: 0;
  }

  50% {
    transform: scale(0.9);
    /* Slight overshoot */
    opacity: 1;
  }

  100% {
    transform: scale(1);
    /* Set to normal size */
    opacity: 1;
  }
}

.photo {
  animation: zoomIn 0.4s ease-out forwards;
}


/*PROJECT PAGE*/
.project {
  width: 84vw;
  margin: 0 8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project__container,
.project__image,
#project-h1,
#project__button {
  margin-bottom: 2.5rem;
}

.project__container {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 2.5fr;
}

#project-h1 {
  text-align: center;
}

.project-p {
  font-family: 'KyivTypeSans-Bold', 'Lato-Bold', 'Open Sans-Bold', sans-serif;
}

.project__image {
  padding: 0;
  width: 84vw;
}

#project__button {
  align-self: flex-end;
}

.project__video {
  width: 84vw;
  height: 42vw;
  margin-bottom: 1.5rem;
}

/*SCROLLBAR*/
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c0c0c0;
}

::-webkit-scrollbar-thumb:hover {
  background: #686868;
}

@media (max-width: 1441px) {
  .about__container--left {
    max-width: 23rem;
  }

  #my-image {
    width: 20rem;
  }

  .image__container {
    margin-top: 5.7rem;
    max-width: 400px;
  }

  .works__container--right {
    max-width: 400px;

  }

  .line {
    width: 400px;
  }
}


@media (max-width: 1025px) {
  .shape {
    scale: 0.6;
  }

  .navbar {
    height: 5rem;
  }

  .navbar a {
    font-size: 1rem;
  }

  .image--left {
    left: -20rem;
  }

  #main-logo {
    font-size: 1rem;
  }

  .home {
    margin: 0 0 0 35vw;
    width: 24rem;
    height: calc(100vh - 5rem);
  }

  h1 {
    font-size: 2.5rem;
  }

  .image--right {
    left: 50vw;
  }

  .about {
    flex-direction: column-reverse;
    justify-content: flex-start;
    height: fit-content;
    overflow: auto;
  }

  .about__container--left {
    max-width: 84vw;
    margin-bottom: 2rem;
  }

  #my-image {
    width: 22.5rem;
    margin-bottom: 2rem;
  }

  .works {
    gap: 1rem;
  }

  .works__container--right {
    max-width: 350px;
  }

  .line {
    width: 350px;
  }

  .image__container {
    margin-top: 5.1rem;
    max-width: 400px;
    min-width: 300px;
  }

  .works__type,
  .works__title,
  p {
    font-size: 0.9rem;
  }
}



@media (max-width: 769px) {
  .shape {
    scale: 0.4;
    top: -400px;
  }

  .navbar a {
    font-size: 0.8rem;
  }

  #main-logo {
    font-size: 0.8rem;
  }

  .home {
    width: 84vw;
    margin: 0 8vw;
  }

  .works__title,
  .works__type,
  p {
    font-size: 0.7rem;
    margin-bottom: 0.4rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  .button__container {
    min-width: 84vw;
  }

  .button {
    font-size: 0.7rem;
    padding: 0.7rem 0;
  }

  .image__container {
    display: none;
  }

  .works__container--right {
    max-width: 84vw;
    width: 84vw;
  }

  .line {
    width: 84vw;
  }

  .project__container {
    grid-template-columns: none;
  }

  .project__category,
  .project__values {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  #my-image {
    width: 83vw;
  }

}

@media (max-width: 321px) {
  #my-image {
    width: 13rem;
    margin-bottom: 1rem;
  }
}